import React from "react";
import "../../App.css";

export default function Statistics() {
  return (
    <div className="stat-container">
      <h1>Statistik</h1>
    </div>
  );
}
