import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../../App.css";
import pool from "../../assets/images/ic_pool.png";
import pooldis from "../../assets/images/ic_pool_disable.png";
import sjonara from "../../assets/images/ic_sjonara.png";
import sjonaradis from "../../assets/images/ic_sjonara_disable.png";
import latrine from "../../assets/images/ic_latrine.png";
import latrinedis from "../../assets/images/ic_latrine_disable.png";
import pets from "../../assets/images/ic_pets.png";
import petsdis from "../../assets/images/ic_pets_disable.png";
import quick from "../../assets/images/ic_quick.png";
import quickdis from "../../assets/images/ic_quickstop_disable.png";
import shopping from "../../assets/images/ic_shopping.png";
import shoppingdis from "../../assets/images/ic_store_disable.png";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import { userAuth } from "../../authContext";

const theme = createTheme({
  palette: {
    primary: { main: "#4c9f70" },
    secondary: { main: "#000" },
  },
});

export default function AddCamping() {
  const [key, setKey] = useState();
  const [type, setType] = useState(1);
  const [name, setName] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [price, setPrice] = useState();
  const [open, setOpen] = useState();
  const [places, setPlaces] = useState();
  const [web, setWeb] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [info, setInfo] = useState();
  const [poolBool, setPool] = useState(false);
  const [shoppingBool, setShopping] = useState(false);
  const [petBool, setPet] = useState(false);
  const [quickBool, setQuick] = useState(false);
  const [sjoBool, setSjo] = useState(false);
  const [latrineBool, setLatrine] = useState(false);

  const makeOneOrZero = (inputBool) => {
    if (inputBool) {
      return 1;
    }
    return 0;
  };

  const validateForm = () => {
    return name.length > 0 && lat.length > 0 && lng.length > 0;
  };

  const getHighestKey = async (typeInput) => {
    let prefix = "";

    if (parseInt(typeInput) === 1) {
      prefix = "FC";
    } else if (parseInt(typeInput) === 2) {
      prefix = "SP";
    } else if (parseInt(typeInput) === 3) {
      prefix = "SCR";
    } else {
      alert("No valid type selected");
    }
    try {
      const response = await fetch(
        `https://us-central1-howfarcanigo-61688.cloudfunctions.net/getHighestKey?type=` +
          typeInput,
        { mode: "cors" }
      );
      const json = await response.json();
      Object.keys(json).forEach(function (k) {
        setKey(prefix + (json[k] + 1));
      });
    } catch (e) {
      console.log(e);
    }
  };
  getHighestKey(type);

  const handleTypeChange = (e) => {
    setType(e.target.value);
    getHighestKey(e.target.value);
  };

  return (
    <div className="page-container">
      <h1>Lägg till Camping {key}</h1>
      <TextField
        required
        id="outlined-required"
        label="Namn"
        defaultValue=""
        fullWidth
        margin="normal"
        onChange={(e) => setName(e.target.value)}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="demo-simple-select-label">Typ</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          label="Typ"
          onChange={(e) => handleTypeChange(e)}
        >
          <MenuItem value={1}>FC</MenuItem>
          <MenuItem value={2}>Ställplats</MenuItem>
          <MenuItem value={3}>SCR</MenuItem>
        </Select>
      </FormControl>
      <TextField
        required
        id="outlined-required"
        label="Latitude"
        defaultValue=""
        margin="normal"
        fullWidth
        onChange={(e) => setLat(e.target.value)}
      />
      <TextField
        required
        id="outlined-required"
        label="Longitude"
        defaultValue=""
        fullWidth
        margin="normal"
        onChange={(e) => setLng(e.target.value)}
      />
      <TextField
        id="outlined-standard"
        label="Pris"
        defaultValue=""
        fullWidth
        margin="normal"
        onChange={(e) => setPrice(e.target.value)}
      />
      <TextField
        id="outlined-standard"
        label="Öppettider"
        defaultValue=""
        fullWidth
        margin="normal"
        onChange={(e) => setOpen(e.target.value)}
      />
      <TextField
        id="outlined-standard"
        label="Platser/Varav el"
        defaultValue=""
        fullWidth
        margin="normal"
        onChange={(e) => setPlaces(e.target.value)}
      />
      <TextField
        id="outlined-standard"
        label="Hemsida"
        defaultValue=""
        fullWidth
        margin="normal"
        onChange={(e) => setWeb(e.target.value)}
      />
      <TextField
        id="outlined-standard"
        label="Telefon"
        defaultValue=""
        fullWidth
        margin="normal"
        onChange={(e) => setPhone(e.target.value)}
      />
      <TextField
        id="outlined-standard"
        label="Email"
        defaultValue=""
        fullWidth
        margin="normal"
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        id="outlined-multiline-flexible"
        label="Information"
        defaultValue=""
        fullWidth
        margin="normal"
        multiline
        maxRows={4}
        onChange={(e) => setInfo(e.target.value)}
      />

      <div className="service-row">
        {poolBool ? (
          <img
            src={pool}
            width="50"
            height="50"
            alt="pool"
            onClick={() => setPool(!poolBool)}
          />
        ) : (
          <img
            src={pooldis}
            width="50"
            height="50"
            alt="no-pool"
            onClick={() => setPool(!poolBool)}
          />
        )}
        {latrineBool ? (
          <img
            src={latrine}
            width="50"
            height="50"
            alt="latrine"
            onClick={() => setLatrine(!latrineBool)}
          />
        ) : (
          <img
            src={latrinedis}
            width="50"
            height="50"
            alt="no-latrine"
            onClick={() => setLatrine(!latrineBool)}
          />
        )}
        {sjoBool ? (
          <img
            src={sjonara}
            width="50"
            height="50"
            alt="sjo"
            onClick={() => setSjo(!sjoBool)}
          />
        ) : (
          <img
            src={sjonaradis}
            width="50"
            height="50"
            alt="no-sjo"
            onClick={() => setSjo(!sjoBool)}
          />
        )}
        {petBool ? (
          <img
            src={pets}
            width="50"
            height="50"
            alt="pets"
            onClick={() => setPet(!petBool)}
          />
        ) : (
          <img
            src={petsdis}
            width="50"
            height="50"
            alt="no-pets"
            onClick={() => setPet(!petBool)}
          />
        )}
        {quickBool ? (
          <img
            src={quick}
            width="50"
            height="50"
            alt="quick"
            onClick={() => setQuick(!quickBool)}
          />
        ) : (
          <img
            src={quickdis}
            width="50"
            height="50"
            alt="no-quick"
            onClick={() => setQuick(!quickBool)}
          />
        )}
        {shoppingBool ? (
          <img
            src={shopping}
            width="50"
            height="50"
            alt="store"
            onClick={() => setShopping(!shoppingBool)}
          />
        ) : (
          <img
            src={shoppingdis}
            width="50"
            height="50"
            alt="no-store"
            onClick={() => setShopping(!shoppingBool)}
          />
        )}
      </div>

      <ThemeProvider theme={theme}>
        <Button
          variant="contained"
          id="search"
          disabled={!userAuth.authenticated || !validateForm()}
          sx={{ my: 1 }}
          color="primary"
          onClick={() => {
            const sendJSON = {
              code: key,
              title: name,
              webPage: web,
              email: email,
              phone: phone,
              open: open,
              price: price,
              places: places,
              lng: lng,
              lat: lat,
              latrine: makeOneOrZero(latrineBool),
              pets: makeOneOrZero(petBool),
              seaView: makeOneOrZero(sjoBool),
              pool: makeOneOrZero(poolBool),
              quickStop: makeOneOrZero(quickBool),
              serviceStore: makeOneOrZero(shoppingBool),
              text: info,
              type: parseInt(type),
              ranking: 0,
              voters: 0,
            };

            if (name !== undefined && lat !== undefined && lng !== undefined) {
              sendToBackend(sendJSON, key);
              setName("");
              setWeb("");
              setEmail("");
              setPhone("");
              setOpen("");
              setPrice("");
              setPlaces("");
              setLng("");
              setLat("");
              setLatrine(false);
              setPet(false);
              setSjo(false);
              setPool(false);
              setQuick(false);
              setShopping(false);
              setInfo("");
            } else {
              alert("Se till att Namn, Lat och Lng är ifyllt");
            }
          }}
        >
          Lägg till
        </Button>
      </ThemeProvider>
    </div>
  );

  function sendToBackend(json, key) {
    return fetch(
      "https://us-central1-howfarcanigo-61688.cloudfunctions.net/addCampingAuth",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: userAuth.authToken,
        },
        body: JSON.stringify(json),
      }
    )
      .then((x) => {
        alert("Bra gjort, du har lagt till " + key);
      })
      .catch((error) => {
        alert(error);
        return false;
      });
  }
}
