import React from "react";
import { Button } from "./Button";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Skriv upp dig för nyhetsbrev
        </p>
        <p className="footer-subscription-text">Avbryt när du vill</p>
        <div className="input-areas">
          <form>
            <input
              type="email"
              name="email"
              placeholder="Din Email"
              className="footer-input"
            />
            <Button buttonStyle="btn--outline">Anmäl dig</Button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Footer;
