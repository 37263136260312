import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import "../../App.css";

import pool from "../../assets/images/ic_pool.png";
import pooldis from "../../assets/images/ic_pool_disable.png";
import sjonara from "../../assets/images/ic_sjonara.png";
import sjonaradis from "../../assets/images/ic_sjonara_disable.png";
import latrine from "../../assets/images/ic_latrine.png";
import latrinedis from "../../assets/images/ic_latrine_disable.png";
import pets from "../../assets/images/ic_pets.png";
import petsdis from "../../assets/images/ic_pets_disable.png";
import quick from "../../assets/images/ic_quick.png";
import quickdis from "../../assets/images/ic_quickstop_disable.png";
import shopping from "../../assets/images/ic_shopping.png";
import shoppingdis from "../../assets/images/ic_store_disable.png";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import { userAuth } from "../../authContext";

const theme = createTheme({
  palette: {
    primary: { main: "#4c9f70" },
    secondary: { main: "#000" },
  },
});

export default function EditCamping() {
  const [search, setSearch] = useState();
  const [results, setResults] = useState([]);
  const [clickedCamping, setClicked] = useState(false);
  const [currentCamping, setCurrentcamping] = useState();
  const [key, setKey] = useState();
  const [type, setType] = useState(1);
  const [name, setName] = useState();
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [price, setPrice] = useState();
  const [open, setOpen] = useState();
  const [places, setPlaces] = useState();
  const [web, setWeb] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [info, setInfo] = useState();
  const [poolBool, setPool] = useState(false);
  const [shoppingBool, setShopping] = useState(false);
  const [petBool, setPet] = useState(false);
  const [quickBool, setQuick] = useState(false);
  const [sjoBool, setSjo] = useState(false);
  const [latrineBool, setLatrine] = useState(false);
  const [rank, setRank] = useState();
  const [voters, setVoters] = useState();

  const makeOneOrZero = (inputBool) => {
    if (inputBool) {
      return 1;
    }
    return 0;
  };

  const performPost = async (json) => {
    try {
      const response = await fetch(
        "https://us-central1-howfarcanigo-61688.cloudfunctions.net/editCamping",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: userAuth.authToken,
          },
          body: JSON.stringify(json),
        }
      );

      const jsonOut = await response.json();
      alert("Du har uppdaterat " + json.title);
      return jsonOut;
    } catch (e) {
      console.log(e);
    }
  };

  const performSearch = async (query) => {
    if (query === undefined) {
      alert("Sök med fler bokstäver");
      return false;
    }
    if (query.length < 3) {
      alert("Sök med fler bokstäver");
      return false;
    } else {
      try {
        const response = await fetch(
          `https://us-central1-howfarcanigo-61688.cloudfunctions.net/getOneCamping?location=${query}`,
          { mode: "cors" }
        );
        const json = await response.json();
        var items = [];
        Object.keys(json).forEach(function (key) {
          items.push(json[key]);
        });

        setResults(items);
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    }
  };

  return (
    <div className="page-container">
      <h1>Korrigera Camping</h1>
      <TextField
        id="outlined-basic"
        label="Sök Camping"
        variant="outlined"
        fullWidth
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <ThemeProvider theme={theme}>
        <Button
          variant="contained"
          id="search"
          disabled={!userAuth.authenticated}
          sx={{ my: 1 }}
          color="primary"
          onClick={() => {
            setClicked(false);
            performSearch(search);
          }}
        >
          Sök
        </Button>
      </ThemeProvider>
      <div className="list-of-campings">
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          {!clickedCamping ? (
            <List>
              {results.map((item, key) => {
                return (
                  <nav aria-label="main mailbox folders">
                    <ListItem disablePadding>
                      <ListItemButton
                        component="a"
                        href="#simple-list"
                        onClick={() => {
                          setCurrentcamping(item);
                          setName(item.title);
                          setKey(item.code);
                          setLat(item.lat);
                          setType(item.type);
                          setLng(item.lng);
                          setPool(item.pool === 1);
                          setQuick(item.quickStop === 1);
                          setLatrine(item.latrine === 1);
                          setSjo(item.seaView === 1);
                          setShopping(item.serviceStore === 1);
                          setPet(item.pets === 1);
                          setClicked(true);
                        }}
                      >
                        <ListItemText
                          primary={item.title}
                          secondary={item.code}
                        />
                      </ListItemButton>
                    </ListItem>
                  </nav>
                );
              })}
            </List>
          ) : (
            <div>
              <TextField
                required
                id="outlined-required"
                label="Namn"
                defaultValue={currentCamping.title}
                fullWidth
                margin="normal"
                onChange={(e) => setName(e.target.value)}
              />

              <TextField
                required
                id="outlined-required"
                label="Latitude"
                defaultValue={currentCamping.lat}
                margin="normal"
                fullWidth
                onChange={(e) => setLat(e.target.value)}
              />
              <TextField
                required
                id="outlined-required"
                label="Longitude"
                defaultValue={currentCamping.lng}
                fullWidth
                margin="normal"
                onChange={(e) => setLng(e.target.value)}
              />
              <TextField
                id="outlined-standard"
                label="Pris"
                defaultValue={currentCamping.price}
                fullWidth
                margin="normal"
                onChange={(e) => setPrice(e.target.value)}
              />
              <TextField
                id="outlined-standard"
                label="Öppettider"
                defaultValue={currentCamping.open}
                fullWidth
                margin="normal"
                onChange={(e) => setOpen(e.target.value)}
              />
              <TextField
                id="outlined-standard"
                label="Platser/Varav el"
                defaultValue={currentCamping.places}
                fullWidth
                margin="normal"
                onChange={(e) => setPlaces(e.target.value)}
              />
              <TextField
                id="outlined-standard"
                label="Hemsida"
                defaultValue={currentCamping.webPage}
                fullWidth
                margin="normal"
                onChange={(e) => setWeb(e.target.value)}
              />
              <TextField
                id="outlined-standard"
                label="Telefon"
                defaultValue={currentCamping.phone}
                fullWidth
                margin="normal"
                onChange={(e) => setPhone(e.target.value)}
              />
              <TextField
                id="outlined-standard"
                label="Email"
                defaultValue={currentCamping.email}
                fullWidth
                margin="normal"
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                id="outlined-standard"
                label="Antal röster"
                defaultValue={currentCamping.voters}
                fullWidth
                margin="normal"
                onChange={(e) => setVoters(e.target.value)}
              />
              <TextField
                id="outlined-standard"
                label="Ranking"
                defaultValue={currentCamping.ranking}
                fullWidth
                margin="normal"
                onChange={(e) => setRank(e.target.value)}
              />
              <TextField
                id="outlined-multiline-flexible"
                label="Information"
                defaultValue={currentCamping.text}
                fullWidth
                margin="normal"
                multiline
                maxRows={4}
                onChange={(e) => setInfo(e.target.value)}
              />

              <div className="service-row">
                {poolBool ? (
                  <img
                    src={pool}
                    width="50"
                    height="50"
                    alt="pool"
                    onClick={() => setPool(!poolBool)}
                  />
                ) : (
                  <img
                    src={pooldis}
                    width="50"
                    height="50"
                    alt="no-pool"
                    onClick={() => setPool(!poolBool)}
                  />
                )}
                {latrineBool ? (
                  <img
                    src={latrine}
                    width="50"
                    height="50"
                    alt="latrine"
                    onClick={() => setLatrine(!latrineBool)}
                  />
                ) : (
                  <img
                    src={latrinedis}
                    width="50"
                    height="50"
                    alt="no-latrine"
                    onClick={() => setLatrine(!latrineBool)}
                  />
                )}
                {sjoBool ? (
                  <img
                    src={sjonara}
                    width="50"
                    height="50"
                    alt="sjo"
                    onClick={() => setSjo(!sjoBool)}
                  />
                ) : (
                  <img
                    src={sjonaradis}
                    width="50"
                    height="50"
                    alt="no-sjo"
                    onClick={() => setSjo(!sjoBool)}
                  />
                )}
                {petBool ? (
                  <img
                    src={pets}
                    width="50"
                    height="50"
                    alt="pets"
                    onClick={() => setPet(!petBool)}
                  />
                ) : (
                  <img
                    src={petsdis}
                    width="50"
                    height="50"
                    alt="no-pets"
                    onClick={() => setPet(!petBool)}
                  />
                )}
                {quickBool ? (
                  <img
                    src={quick}
                    width="50"
                    height="50"
                    alt="quick"
                    onClick={() => setQuick(!quickBool)}
                  />
                ) : (
                  <img
                    src={quickdis}
                    width="50"
                    height="50"
                    alt="no-quick"
                    onClick={() => setQuick(!quickBool)}
                  />
                )}
                {shoppingBool ? (
                  <img
                    src={shopping}
                    width="50"
                    height="50"
                    alt="store"
                    onClick={() => setShopping(!shoppingBool)}
                  />
                ) : (
                  <img
                    src={shoppingdis}
                    width="50"
                    height="50"
                    alt="no-store"
                    onClick={() => setShopping(!shoppingBool)}
                  />
                )}
              </div>

              <ThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  id="send"
                  sx={{ my: 1 }}
                  color="primary"
                  onClick={() => {
                    const sendJSON = {
                      code: key,
                      title: name,
                      webPage: web,
                      email: email,
                      phone: phone,
                      open: open,
                      price: price,
                      places: places,
                      lng: lng,
                      lat: lat,
                      latrine: makeOneOrZero(latrineBool),
                      pets: makeOneOrZero(petBool),
                      seaView: makeOneOrZero(sjoBool),
                      pool: makeOneOrZero(poolBool),
                      quickStop: makeOneOrZero(quickBool),
                      serviceStore: makeOneOrZero(shoppingBool),
                      text: info,
                      type: parseInt(type),
                      ranking: rank,
                      voters: voters,
                    };

                    if (
                      name !== undefined &&
                      lat !== undefined &&
                      lng !== undefined
                    ) {
                      performPost(sendJSON);
                      setClicked(false);
                    } else {
                      alert("Se till att Namn, Lat och Lng är ifyllt");
                    }
                  }}
                >
                  Uppdatera
                </Button>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  id="back"
                  color="primary"
                  sx={{ m: 1 }}
                  onClick={() => {
                    setClicked(false);
                  }}
                >
                  Bakåt
                </Button>
              </ThemeProvider>
            </div>
          )}
        </Box>
      </div>
    </div>
  );
}
