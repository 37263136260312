import "./App.css";
import React, { useEffect } from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Edit from "./components/pages/EditCamping";
import Home from "./components/pages/Home";
import Add from "./components/pages/AddCamping";
import Stats from "./components/pages/Statistics";
import Login from "./components/pages/LogIn";

function App() {
  useEffect(() => {}, []);
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/edit" exact component={Edit} />
          <Route path="/add" exact component={Add} />
          <Route path="/stats" exact component={Stats} />
          <Route path="/login" exact component={Login} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
