import React, { useState } from "react";

import { TextField, Button } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import { userAuth } from "../../authContext";

const theme = createTheme({
  palette: {
    primary: { main: "#4c9f70" },
    secondary: { main: "#000" },
  },
});

const firebaseConfig = {
  apiKey: "AIzaSyAcxxdb86ulhMsAvDyXC7JTljb0nvSoq1g",
  authDomain: "howfarcanigo-61688.firebaseapp.com",
  databaseURL: "https://howfarcanigo-61688.firebaseio.com",
  projectId: "howfarcanigo-61688",
  storageBucket: "howfarcanigo-61688.appspot.com",
  messagingSenderId: "788300433827",
  appId: "1:788300433827:web:67babdce7d2a6f0b6bb216",
  measurementId: "G-X60ZMVP91V",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}
firebase.auth();

function LogIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const signin = (email, password) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        userAuth.authToken = res.user.uid;
        userAuth.authenticated = true;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="page-container">
      <h1>Logga in</h1>
      <TextField
        id="outlined-basic-email"
        autoFocus
        label="Email"
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        id="outlined-basic-password"
        label="Lösenord"
        variant="outlined"
        type="password"
        margin="normal"
        fullWidth
        onChange={(e) => setPassword(e.target.value)}
      />
      <ThemeProvider theme={theme}>
        <Button
          variant="contained"
          id="submit"
          sx={{ my: 1 }}
          color="primary"
          disabled={!validateForm()}
          onClick={() => {
            setPassword("");
            setEmail("");
            signin(email, password);
          }}
        >
          Logga in
        </Button>
      </ThemeProvider>
    </div>
  );
}

export default LogIn;
